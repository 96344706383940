import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';

const NEW_VEHICLES_GOAL = 60;
const USED_VEHICLES_GOAL = 35;

const ignoreIndividualGoals = [
  'NAVA,ALFREDO',
  'CALZADILLAS,GENARO H',
  'TARROZA,BIEN',
  'NGUYEN,THANH',
];

const ignoreSalesManager = [
  'NAVA,ALFREDO',
  'BAHROOS,KABIR',
  'KHAN,SAIF',
  'HOUSE',
];

const calculatePace = (value) => {
  const today = dayjs();
  const startOfMonth = today.startOf('month');
  const totalDaysInMonth = today.daysInMonth();
  const daysElapsed = today.diff(startOfMonth, 'day');
  const pace = (value / daysElapsed) * totalDaysInMonth;
  return Math.round(pace);
};

const SalesManagerPerformanceTable = ({ data }) => {
  const salesManagerData = useMemo(() => {
    const managerData = {};

    data.filter(item => 
      (item.FiWipStatusCode === 'F' || item.FiWipStatusCode === 'B') &&
      item.DealType !== 'Wholesale'
    ).forEach(item => {
      if (item.CRMSalesMgrName && item.CRMSalesMgrName.trim() !== '' && !ignoreSalesManager.includes(item.CRMSalesMgrName)) {
        if (!managerData[item.CRMSalesMgrName]) {
          managerData[item.CRMSalesMgrName] = {
            name: item.CRMSalesMgrName,
            newUnits: 0,
            usedUnits: 0,
            newGross: 0,
            usedGross: 0,
          };
        }

        const isNewVehicle = item.DealType === 'New';
        const dealCreditArray = [
          { name: item.CRMSP1Name, credit: item.CRMSaleCreditSP1 || 0 },
          { name: item.CRMSP2Name, credit: item.CRMSaleCreditSP2 || 0 },
          { name: item.CRMSP3Name, credit: item.CRMSaleCreditSP3 || 0 },
        ];

        const filteredDealCreditArray = dealCreditArray.filter(
          dealCredit => !ignoreIndividualGoals.includes(dealCredit.name)
        );

        const totalSalesCredit = filteredDealCreditArray.reduce(
          (acc, dealCredit) => acc + parseFloat(dealCredit.credit),
          0
        );

        const units = totalSalesCredit / 1000;
        const gross = parseFloat(item.TotalGross) || 0;

        if (isNewVehicle) {
          managerData[item.CRMSalesMgrName].newUnits += units;
          managerData[item.CRMSalesMgrName].newGross += gross;
        } else {
          managerData[item.CRMSalesMgrName].usedUnits += units;
          managerData[item.CRMSalesMgrName].usedGross += gross;
        }
      }
    });

    return Object.values(managerData).map(manager => ({
      ...manager,
      totalUnits: manager.newUnits + manager.usedUnits,
      totalGross: manager.newGross + manager.usedGross,
      newUnitsPace: calculatePace(manager.newUnits),
      usedUnitsPace: calculatePace(manager.usedUnits),
      newPVR: manager.newUnits > 0 ? manager.newGross / manager.newUnits : 0,
      usedPVR: manager.usedUnits > 0 ? manager.usedGross / manager.usedUnits : 0,
      totalPVR: (manager.newUnits + manager.usedUnits) > 0 ? (manager.newGross + manager.usedGross) / (manager.newUnits + manager.usedUnits) : 0,
    }));
  }, [data]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            Sales Manager Performance
          </Typography>
          <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
            New Vehicles Goal: {NEW_VEHICLES_GOAL} | Used Vehicles Goal: {USED_VEHICLES_GOAL}
          </Typography>
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="sales manager performance table">
          <TableHead>
            <TableRow>
              <TableCell>Manager</TableCell>
              <TableCell align="right">New Units</TableCell>
              <TableCell align="right">New Pace</TableCell>
              <TableCell align="right">New Gross</TableCell>
              <TableCell align="right">New PVR</TableCell>
              <TableCell align="right">Used Units</TableCell>
              <TableCell align="right">Used Pace</TableCell>
              <TableCell align="right">Used Gross</TableCell>
              <TableCell align="right">Used PVR</TableCell>
              <TableCell align="right">Total Units</TableCell>
              <TableCell align="right">Total Gross</TableCell>
              <TableCell align="right">Total PVR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesManagerData.map((manager, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {manager.name}
                </TableCell>
                <TableCell align="right">{formatNumber(manager.newUnits)}</TableCell>
                <TableCell align="right">{formatNumber(manager.newUnitsPace)} / {NEW_VEHICLES_GOAL}</TableCell>
                <TableCell align="right">{formatCurrency(manager.newGross)}</TableCell>
                <TableCell align="right">{formatCurrency(manager.newPVR)}</TableCell>
                <TableCell align="right">{formatNumber(manager.usedUnits)}</TableCell>
                <TableCell align="right">{formatNumber(manager.usedUnitsPace)} / {USED_VEHICLES_GOAL}</TableCell>
                <TableCell align="right">{formatCurrency(manager.usedGross)}</TableCell>
                <TableCell align="right">{formatCurrency(manager.usedPVR)}</TableCell>
                <TableCell align="right">{formatNumber(manager.totalUnits)}</TableCell>
                <TableCell align="right">{formatCurrency(manager.totalGross)}</TableCell>
                <TableCell align="right">{formatCurrency(manager.totalPVR)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SalesManagerPerformanceTable;