import React, { useState, useMemo, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Snackbar,
  Alert,
  Tooltip,
  Fab,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { API_BASE_URL } from 'config';
import { useEmployees } from 'hooks/useEmployees';
import SearchCoversheets from './SearchCoversheets';
import PrintCoversheet from './PrintCoversheet';

import CustomerInformation from './CustomerInformation';
import VehicleInformation from './VehicleInformation';
import PersonnelInformation from './PersonnelInformation';
import DealStructure from './DealStructure';
import Checklists from './Checklists';
import Trades from './Trades';
import ProductsAndStips from './ProductsAndStips';
import Notes from './Notes';

// Import checklist items and other constants
import {
  dealChecklistItems,
  customerChecklistItems,
  newChecklistItems,
  usedChecklistItems,
  taxableChecklistItems,
  nontaxableChecklistItems,
  stipChecklistItems,
} from './checklistItems'; 

const customFilterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.name,
});

const Coversheet = ({ viewDealNo, dealerId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [errorFields, setErrorFields] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const formattedDate = useMemo(() => {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }, []);

  const Employees = useEmployees(user, isAuthenticated);

  const SalesPeople = useMemo(() => {
    if (!Array.isArray(Employees.data)) return [];
    const salesPeople = Employees.data
      .filter(
        (employee) =>
          employee &&
          employee.isActive &&
          employee.accessRole === 'Sales Consultant',
      )
      .map(
        (employee) =>
          `${employee.firstName || ''} ${employee.lastName || ''} (${
            employee.dmsID || ''
          })`,
      )
      .filter(Boolean); // Remove any empty strings
    salesPeople.push('House', '');
    return salesPeople;
  }, [Employees.data]);

  const SalesManagers = useMemo(() => {
    if (!Array.isArray(Employees.data)) return [];
    const salesManagers = Employees.data
      .filter(
        (employee) =>
          employee &&
          employee.isActive &&
          employee.accessRole === 'Sales Manager',
      )
      .map(
        (employee) => `${employee.firstName || ''} ${employee.lastName || ''}`,
      )
      .filter(Boolean); // Remove any empty strings
    salesManagers.push('House', '');
    return salesManagers;
  }, [Employees.data]);

  const TeamLeaders = useMemo(() => {
    if (!Array.isArray(Employees.data)) return [];
    const teamLeaders = Employees.data
      .filter(
        (employee) =>
          employee &&
          employee.isActive &&
          employee.accessRole === 'Team Leader',
      )
      .map(
        (employee) => `${employee.firstName || ''} ${employee.lastName || ''}`,
      )
      .filter(Boolean); // Remove any empty strings
    teamLeaders.push('House', '');
    return teamLeaders;
  }, [Employees.data]);

  const initialFormData = useMemo(() => {
    return {
      dealerID: user?.dealerid,
      date: formattedDate,
      dealno: '',
      email: '',
      phone: '',
      buyername: '',
      buyerno: '',
      buyerbureau: '',
      buyerscore: '',
      cobuyername: '',
      cobuyerno: '',
      cobuyerbureau: '',
      cobuyerscore: '',
      stockno: '',
      vehicletype: '',
      year: '',
      make: '',
      model: '',
      miles: '',
      sp1: '',
      sp2: '',
      team: '',
      salesmanager: '',
      teamleaders: '',
      fimanager: '',
      source: '',
      vehiclestatus: '',
      purchasetype: '',
      downpayment: '',
      term: '',
      rate: '',
      payment: '',
      taxverification: false,
      tradestock1: '',
      tradeyear1: '',
      trademake1: '',
      trademodel1: '',
      trademiles1: '',
      tradeallow1: '',
      tradeacv1: '',
      tradepayoff1: '',
      tradestock2: '',
      tradeyear2: '',
      trademake2: '',
      trademodel2: '',
      trademiles2: '',
      tradeallow2: '',
      tradeacv2: '',
      tradepayoff2: '',
      notes: '',
      stip1: false,
      stip2: false,
      stip3: false,
      stip4: false,
      stip5: false,
      stip1Desc: 'Proof of Income',
      stip2Desc: 'Proof of Residence',
      stip3Desc: 'SSA-89 Form',
      stip4Desc: '',
      stip5Desc: '',
      presold1: false,
      presold2: false,
      presold3: false,
      presold4: false,
      presold5: false,
      presold6: false,
      presold7: false,
      presold8: false,
      presold9: false,
      presold10: false,
      presold1Desc: 'KARR Alarm',
      presold2Desc: 'Cilajet',
      presold3Desc: 'CAT Protection',
      presold4Desc: '',
      presold5Desc: '',
      presold6Desc: 'Vehicle Service Contract',
      presold7Desc: 'GAP',
      presold8Desc: 'Prepaid Maintenance',
      presold9Desc: 'Tire & Wheel',
      presold10Desc: 'Lease Wear & Tear',
      booksheet: false,
      salesmenu: false,
      dealscreen: false,
      gscreen: false,
      inoutform: false,
      reserved: false,
      rebateforms: false,
      driverslicense: false,
      insurance: false,
      creditapp: false,
      creditreport: false,
      ofac: false,
      invoice: false,
      addendum: false,
      connectedservices: false,
      pdichecklist: false,
      partialautomationdisclosure: false,
      newrecallverification: false,
      buyersguide: false,
      carfax: false,
      ksr: false,
      cpochecklist: false,
      usedrecallverification: false,
      tradecarfax: false,
      tradeksr: false,
      tradenmvtis: false,
      tradebooksheet: false,
      tradepayoff: false,
      tradeappraisal: false,
      traderegistration: false,
      traderecall: false,
      redflags: false,
      dmvcalc: false,
    };
  }, [formattedDate, user?.dealerid]);

  const [formData, setFormData] = useState(initialFormData);

  const requiredFields = [
    'dealerID',
    'date',
    'dealno',
    'buyername',
    'buyerno',
    'stockno',
    'vehicletype',
    'year',
    'make',
    'model',
    'miles',
    'sp1',
    'salesmanager',
    'vehiclestatus',
    'purchasetype',
  ];

  useEffect(() => {
    if (viewDealNo && dealerId) {
      const getCoversheetByDealNo = async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch(
            `${API_BASE_URL}/api/coversheets/${dealerId}/${viewDealNo}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.ok) {
            const coversheet = await response.json();
            coversheet.date = coversheet.date
              ? coversheet.date.split('T')[0]
              : '';
            setFormData(coversheet);
          } else {
            setErrorMessage('Failed to fetch Cover Sheet data');
          }
        } catch (error) {
          console.error(error);
          setErrorMessage(
            'An error occurred while fetching the Cover Sheet data',
          );
        }
      };

      getCoversheetByDealNo();
    }
  }, [viewDealNo, dealerId, getAccessTokenSilently]);

  const resetForm = () => setFormData(initialFormData);
  const handleSave = async () => {
    // Remove _id and __v fields from the formData object
    const { _id, __v, ...formDataWithoutIdAndV } = formData;

    // Trim the values of each field before saving
    for (const field in formDataWithoutIdAndV) {
      if (typeof formDataWithoutIdAndV[field] === 'string') {
        formDataWithoutIdAndV[field] = formDataWithoutIdAndV[field].trim();
      }
    }

    const missingFields = [];

    for (let field of requiredFields) {
      if (
        !formDataWithoutIdAndV[field] ||
        (typeof formDataWithoutIdAndV[field] === 'string' &&
          formDataWithoutIdAndV[field].trim() === '')
      ) {
        missingFields.push(field);
      }
    }

    setErrorFields(missingFields);

    if (missingFields.length > 0) {
      setErrorMessage(
        `Please fill out the required fields: ${missingFields.join(', ')}`,
      );
      return;
    }

    console.log('Submitting Cover Sheet data:', formDataWithoutIdAndV);

    // Continue with submit
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_BASE_URL}/api/coversheets/${formData.dealno}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formDataWithoutIdAndV),
        },
      );

      if (response.ok) {
        setSuccessMessage('Cover Sheet data submitted successfully');
        console.log('Cover Sheet data submitted successfully');
      } else {
        setErrorMessage('Failed to submit Cover Sheet data');
        throw new Error('Failed to submit Cover Sheet data');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'An error occurred while submitting the Cover Sheet data',
      );
    }
  };

  const handlePrint = async () => {
    const missingFields = requiredFields.filter(
      (field) => !formData[field] || formData[field].trim() === '',
    );

    if (missingFields.length > 0) {
      setErrorMessage(
        `Please fill out the required fields: ${missingFields.join(', ')}`,
      );
      return;
    }

    // Auto Save Logic
    await handleSave();

    // Trigger print
    window.print();
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleBlur = (field) => {
    if (
      errorFields.includes(field) &&
      formData[field] &&
      formData[field].trim() !== ''
    ) {
      setErrorFields((prevErrors) =>
        prevErrors.filter((error) => error !== field),
      );
    }
  };

  const handleCurrencyBlur = (event) => {
    const { name, value } = event.target;
    const formattedValue = formatCurrency(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };

  const formatCurrency = (value) => {
    const numberValue = Number(value);
    return !isNaN(numberValue)
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(numberValue)
      : '';
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the input
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Apply the desired phone number format
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    // Return the original value if the input doesn't match the expected format
    return phoneNumber;
  };

  const handlePhoneChange = (event) => {
    const phone = event.target.value;

    const cleanedPhone = phone.replace(/\D/g, '').slice(0, 10);

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: cleanedPhone,
    }));
  };

  const handleSearchSelect = (selectedCoversheet) => {
    setFormData((prevData) => ({
      ...prevData,
      ...selectedCoversheet,
      // Ensure the date is in the correct format
      date: selectedCoversheet.date || formattedDate,
    }));
  };

  return (
    isAuthenticated && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              {!viewDealNo && (
                <CardHeader
                  title={
                    <SearchCoversheets
                      onSelect={handleSearchSelect}
                      dealerID={user?.dealerid}
                    />
                  }
                  action={
                    <Stack
                      direction={isMobile ? 'column' : 'row'}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="outlined"
                        startIcon={<RestartAltIcon />}
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </Stack>
                  }
                  sx={{
                    borderBottom: 1,
                    borderColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[300]
                        : theme.palette.grey[800],
                  }}
                />
              )}
              <CardHeader
                title="Customer Information"
                subheader="Enter the customer's personal details and contact information."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <CustomerInformation
                  formData={formData}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorFields={errorFields}
                  handlePhoneChange={handlePhoneChange}
                  formatPhoneNumber={formatPhoneNumber}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Vehicle Information"
                subheader="Provide details about the vehicle being sold or leased."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <VehicleInformation
                  formData={formData}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorFields={errorFields}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Personnel Information"
                subheader="Select the sales staff involved in this transaction."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <PersonnelInformation
                  formData={formData}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorFields={errorFields}
                  SalesPeople={SalesPeople}
                  SalesManagers={SalesManagers}
                  TeamLeaders={TeamLeaders}
                  filterOptions={customFilterOptions}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Deal Structure"
                subheader="Enter the financial details and terms of the deal."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <DealStructure
                  formData={formData}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorFields={errorFields}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Document Checklists"
                subheader="Verify all required documents are collected and processes are completed."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Checklists
                  formData={formData}
                  handleCheckboxChange={handleCheckboxChange}
                  checklistItems={{
                    Deal: dealChecklistItems,
                    Customer: customerChecklistItems,
                    'New Vehicle': newChecklistItems,
                    'Used Vehicle': usedChecklistItems,
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Trade-In Information"
                subheader="If applicable, enter details about any trade-in vehicles."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Trades
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyBlur={handleCurrencyBlur}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Products and Stipulations"
                subheader="Record any additional products sold and stipulations for the deal."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <ProductsAndStips
                  formData={formData}
                  handleChange={handleChange}
                  handleCheckboxChange={handleCheckboxChange}
                  taxableItems={taxableChecklistItems}
                  nontaxableItems={nontaxableChecklistItems}
                  stipItems={stipChecklistItems}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardHeader
                title="Additional Notes"
                subheader="Add any additional comments or notes relevant to this deal."
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Notes formData={formData} handleChange={handleChange} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Tooltip title="Save changes" placement="left">
          <Fab
            color="primary"
            aria-label="save"
            onClick={handleSave}
            sx={{ position: 'fixed', top: '50%', right: 16 }}
          >
            <SaveIcon />
          </Fab>
        </Tooltip>

        <PrintCoversheet formData={formData} />

        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setErrorMessage(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSuccessMessage(null)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </>
    )
  );
};

export default Coversheet;
