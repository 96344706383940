import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Paper, 
  TextField, 
  Button, 
  Tabs, 
  Tab, 
  Divider, 
  Alert, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow 
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { getTaxRateByAddress, getTaxRateByCoordinates } from '../../api/taxRateAPI'; // Adjust the import path as necessary

// TabPanel component for switching between address and coordinates lookup
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tax-rate-tabpanel-${index}`}
      aria-labelledby={`tax-rate-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CaliforniaTaxRateLookup = () => {
  // Tab state
  const [tabValue, setTabValue] = useState(0);
  
  // Form states
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  
  // Results and UI states
  const [taxResults, setTaxResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Reset results when switching tabs
    setTaxResults(null);
    setError(null);
  };
  
  // Handle "Use Current Location" button
  const handleUseCurrentLocation = () => {
    setError(null);
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setError(`Geolocation error: ${err.message}`);
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }
  };
  
  // We don't need this function anymore as it's handled in the service
  
  // Lookup tax rate by address
  const lookupTaxRateByAddress = async () => {
    if (!address || !city || !zip) {
      setError('Please fill out all address fields');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      const data = await getTaxRateByAddress(address, city, zip);
      setTaxResults(data);
    } catch (err) {
      setError(`Failed to fetch tax rate: ${err.response?.data?.errors?.[0]?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Lookup tax rate by coordinates
  const lookupTaxRateByCoordinates = async () => {
    if (!latitude || !longitude) {
      setError('Please provide both latitude and longitude');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      const data = await getTaxRateByCoordinates(longitude, latitude);
      setTaxResults(data);
    } catch (err) {
      setError(`Failed to fetch tax rate: ${err.response?.data?.errors?.[0]?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  // Format the tax rate as percentage
  const formatTaxRate = (rate) => {
    return (rate * 100).toFixed(2) + '%';
  };
  
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          California Tax Rate Lookup
        </Typography>
        
        <Typography variant="body1" paragraph align="center" color="text.secondary">
          Check sales tax rates for any location in California
        </Typography>
        
        <Divider sx={{ mb: 2 }} />
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            aria-label="tax rate lookup methods"
            centered
          >
            <Tab 
              icon={<LocationOnIcon />} 
              label="Lookup by Address" 
              id="tax-rate-tab-0" 
              aria-controls="tax-rate-tabpanel-0" 
            />
            <Tab 
              icon={<MyLocationIcon />} 
              label="Lookup by Coordinates" 
              id="tax-rate-tab-1" 
              aria-controls="tax-rate-tabpanel-1" 
            />
          </Tabs>
        </Box>
        
        {/* Address Lookup Tab */}
        <TabPanel value={tabValue} index={0}>
          <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
            <TextField
              fullWidth
              required
              id="address"
              label="Street Address"
              margin="normal"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="e.g. 450 N St"
            />
            
            <TextField
              fullWidth
              required
              id="city"
              label="City"
              margin="normal"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="e.g. Sacramento"
            />
            
            <TextField
              fullWidth
              required
              id="zip"
              label="ZIP Code"
              margin="normal"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              placeholder="e.g. 95814"
              inputProps={{ maxLength: 5 }}
            />
            
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={lookupTaxRateByAddress}
              disabled={loading}
              sx={{ mt: 2, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Look Up Tax Rate'}
            </Button>
          </Box>
        </TabPanel>
        
        {/* Coordinates Lookup Tab */}
        <TabPanel value={tabValue} index={1}>
          <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<MyLocationIcon />}
              onClick={handleUseCurrentLocation}
              sx={{ mb: 2 }}
            >
              Use Current Location
            </Button>
            
            <TextField
              fullWidth
              required
              id="latitude"
              label="Latitude"
              margin="normal"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              placeholder="e.g. 38.577650"
              type="number"
              inputProps={{ step: "any" }}
            />
            
            <TextField
              fullWidth
              required
              id="longitude"
              label="Longitude"
              margin="normal"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              placeholder="e.g. -121.502520"
              type="number"
              inputProps={{ step: "any" }}
            />
            
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={lookupTaxRateByCoordinates}
              disabled={loading}
              sx={{ mt: 2, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Look Up Tax Rate'}
            </Button>
          </Box>
        </TabPanel>
        
        {/* Error message */}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        
        {/* Results Section */}
        {taxResults && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Results
            </Typography>
            
            <Divider sx={{ mb: 2 }} />
            
            {taxResults.taxRateInfo && taxResults.taxRateInfo.length > 0 ? (
              <>
                <TableContainer component={Paper} variant="outlined">
                  <Table aria-label="tax rate results">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tax Rate</TableCell>
                        <TableCell>Jurisdiction</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>County</TableCell>
                        <TableCell>Tax Area Code</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taxResults.taxRateInfo.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            {formatTaxRate(item.rate)}
                          </TableCell>
                          <TableCell>{item.jurisdiction}</TableCell>
                          <TableCell>{item.city}</TableCell>
                          <TableCell>{item.county}</TableCell>
                          <TableCell>{item.tac}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                {/* Address matching information if available */}
                {taxResults.geocodeInfo && taxResults.geocodeInfo.formattedAddress && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Matched address: {taxResults.geocodeInfo.formattedAddress}
                    </Typography>
                    {taxResults.geocodeInfo.confidence && (
                      <Typography variant="body2" color="text.secondary">
                        Match confidence: {taxResults.geocodeInfo.confidence}
                      </Typography>
                    )}
                  </Box>
                )}
                
                {/* Links to terms and disclaimer */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    <a href={taxResults.termsOfUse} target="_blank" rel="noopener noreferrer">
                      Terms of Use
                    </a>
                    {' | '}
                    <a href={taxResults.disclaimer} target="_blank" rel="noopener noreferrer">
                      Disclaimer
                    </a>
                  </Typography>
                </Box>
              </>
            ) : (
              <Alert severity="info">
                No tax rate information found for this location.
              </Alert>
            )}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default CaliforniaTaxRateLookup;