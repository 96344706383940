import axios from 'axios';
import { API_BASE_URL } from '../config';

/**
 * Fetch tax rate by address
 * @param {string} address - Street address
 * @param {string} city - City name
 * @param {string} zip - ZIP code
 * @returns {Promise} - Promise resolving to tax rate data
 */
export const getTaxRateByAddress = async (address, city, zip) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tax-rates/address`, {
      params: {
        address,
        city,
        zip
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching tax rate by address:', error);
    throw error;
  }
};

/**
 * Fetch tax rate by geographical coordinates
 * @param {number} longitude - Longitude value
 * @param {number} latitude - Latitude value
 * @returns {Promise} - Promise resolving to tax rate data
 */
export const getTaxRateByCoordinates = async (longitude, latitude) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tax-rates/coordinates`, {
      params: {
        longitude,
        latitude
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching tax rate by coordinates:', error);
    throw error;
  }
};