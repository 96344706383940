import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  useTheme,
  Alert
} from '@mui/material';

const PrivacyPolicy = () => {
  const theme = useTheme();
  
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, md: 5 }, borderRadius: 2 }}>
        <Box sx={{ textAlign: 'center', mb: 4, pb: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Typography variant="h3" component="h1" gutterBottom color="primary">
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ fontStyle: 'italic' }}>
            Last updated: January 1, 2025
          </Typography>
        </Box>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Introduction
        </Typography>
        <Typography paragraph>
          This Privacy Policy describes how Dealer Software Solutions, LLC ("we", "us", or "our") collects, uses, and discloses your information when you visit our website (the "Site") and use our services. By accessing or using our Site, you agree to the practices described in this Privacy Policy.
        </Typography>
        
        <Alert severity="info" sx={{ my: 3 }}>
          We are committed to protecting your privacy and ensuring your personal information is handled responsibly. This policy explains your rights and our obligations regarding your data.
        </Alert>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Information We Collect
        </Typography>
        
        <Typography variant="h5" component="h3" gutterBottom color="secondary" sx={{ mt: 3 }}>
          Personal Information
        </Typography>
        <Typography paragraph>
          We may collect personally identifiable information ("Personal Information") that you voluntarily provide when you register on our Site, subscribe to our newsletter, or contact us. This may include:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Name, email address, and phone number" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Billing and payment information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Company information (for business customers)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Account login credentials" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Communication preferences" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Any other information you choose to share when contacting us" />
          </ListItem>
        </List>
        
        <Typography variant="h5" component="h3" gutterBottom color="secondary" sx={{ mt: 3 }}>
          Non-Personal Information
        </Typography>
        <Typography paragraph>
          We also collect non-personal information through cookies and other tracking technologies, such as:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Device information (browser type, operating system, device type)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="IP address and general location information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Usage data and browsing patterns" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Referring websites and interactions with our Site" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Performance and error data" />
          </ListItem>
        </List>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Legal Basis for Processing (For EEA, UK, and Similar Jurisdictions)
        </Typography>
        <Typography paragraph>
          We process your personal data on the following legal grounds:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText 
              primary="Contract fulfillment" 
              secondary="To provide services you have requested or to take steps at your request before entering into a contract" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Legitimate interests" 
              secondary="For our legitimate business purposes, provided your rights don't override these interests" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Consent" 
              secondary="When you have given explicit consent to process your data for specific purposes" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Legal obligation" 
              secondary="To comply with legal requirements" 
            />
          </ListItem>
        </List>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use the information we collect to:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Provide, maintain, and improve our services" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Process transactions and send related information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Personalize your experience on our Site" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Send periodic updates, newsletters, and marketing communications" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Respond to your comments, questions, and customer service requests" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Analyze usage and trends to enhance our services" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Detect, investigate, and prevent fraudulent transactions and other illegal activities" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Comply with our legal obligations" />
          </ListItem>
        </List>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Disclosure of Your Information
        </Typography>
        <Typography paragraph>
          We do not sell or rent your Personal Information to third parties. We may disclose your information in the following situations:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="To comply with legal obligations, court orders, or to protect our rights" />
          </ListItem>
          <ListItem>
            <ListItemText primary="To trusted third-party service providers who assist us in operating our Site and providing services, subject to confidentiality agreements" />
          </ListItem>
          <ListItem>
            <ListItemText primary="In connection with a merger, acquisition, or sale of business assets" />
          </ListItem>
          <ListItem>
            <ListItemText primary="With your consent or at your direction" />
          </ListItem>
        </List>
        
        <Typography variant="h5" component="h3" gutterBottom color="secondary" sx={{ mt: 3 }}>
          Categories of Third-Party Recipients
        </Typography>
        <TableContainer component={Paper} variant="outlined" sx={{ my: 2 }}>
          <Table aria-label="third-party recipients table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="subtitle2">Recipient Category</Typography></TableCell>
                <TableCell><Typography variant="subtitle2">Purpose</Typography></TableCell>
                <TableCell><Typography variant="subtitle2">Data Shared</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Cloud Service Providers</TableCell>
                <TableCell>Hosting and infrastructure</TableCell>
                <TableCell>Account data, usage information</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Analytics Providers</TableCell>
                <TableCell>Website performance analysis</TableCell>
                <TableCell>Usage data, IP address</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payment Processors</TableCell>
                <TableCell>Process transactions</TableCell>
                <TableCell>Payment information</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email Service Providers</TableCell>
                <TableCell>Send communications</TableCell>
                <TableCell>Contact information</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Data Retention
        </Typography>
        <Typography paragraph>
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. The criteria used to determine our retention periods include:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="The length of time we have an ongoing relationship with you" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Whether there is a legal obligation to which we are subject" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Whether retention is advisable in light of our legal position (such as statutes of limitations, litigation, or regulatory investigations)" />
          </ListItem>
        </List>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Security
        </Typography>
        <Typography paragraph>
          We implement appropriate technical and organizational security measures to protect your Personal Information from unauthorized access, alteration, disclosure, or destruction. These measures include:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Encryption of sensitive data" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Secure networks and systems" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Regular security assessments" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Access controls and authentication procedures" />
          </ListItem>
        </List>
        <Typography paragraph>
          However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Cookies and Tracking Technologies
        </Typography>
        <Typography paragraph>
          Our Site uses cookies, web beacons, and similar technologies to enhance user experience and collect information about how you use our Site. These technologies help us:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Remember your preferences and settings" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Understand how you interact with our Site" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Improve our services based on usage patterns" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Provide personalized content and advertisements" />
          </ListItem>
        </List>
        <Typography paragraph>
          You can control cookie settings through your browser preferences. Disabling cookies may impact your ability to use certain features of our Site.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Third-Party Links
        </Typography>
        <Typography paragraph>
          Our Site may include links to third-party websites, plugins, and applications that are not operated by us. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We are not responsible for the privacy practices or content of these external sites, and we encourage you to review their privacy policies.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Children's Privacy
        </Typography>
        <Typography paragraph>
          Our services are not directed to individuals under the age of 16. We do not knowingly collect personal information from children. If you are a parent or guardian and believe we have collected information from your child, please contact us immediately.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          International Data Transfers
        </Typography>
        <Typography paragraph>
          Your information may be transferred to, and processed in, countries other than the country in which you reside. These countries may have data protection laws that differ from those in your country. When we transfer your information, we use appropriate safeguards in accordance with applicable law to ensure that your personal information remains protected.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Your Rights
        </Typography>
        <Typography paragraph>
          Depending on your location, you may have the following rights regarding your personal information:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText 
              primary="Access" 
              secondary="Request a copy of the personal information we hold about you" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Correction" 
              secondary="Request correction of inaccurate or incomplete information" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Deletion" 
              secondary="Request deletion of your personal information where there is no compelling reason for continued processing" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Restriction" 
              secondary="Request restriction of processing in certain circumstances" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Portability" 
              secondary="Request transfer of your personal information to another service provider" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Objection" 
              secondary="Object to processing based on legitimate interests or for direct marketing" 
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Withdraw consent" 
              secondary="Withdraw consent where processing is based on your consent" 
            />
          </ListItem>
        </List>
        <Typography paragraph>
          To exercise these rights or if you have any questions about this Privacy Policy, please contact us using the details provided below. We will respond to your request within the timeframe required by applicable law.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          California Privacy Rights
        </Typography>
        <Typography paragraph>
          If you are a California resident, you have specific rights under the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA). These rights include:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText primary="The right to know what personal information we collect, use, disclose, and sell" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The right to delete certain personal information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The right to opt-out of the sale or sharing of personal information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The right to non-discrimination for exercising your rights" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The right to limit the use and disclosure of sensitive personal information" />
          </ListItem>
        </List>
        <Typography paragraph>
          To exercise these rights, please contact us using the information provided in the "Contact Us" section.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Changes to This Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated effective date. For significant changes, we will provide a more prominent notice or direct notification. Your continued use of the Site after such modifications constitutes your acknowledgment of the modified Privacy Policy and agreement to abide by its terms.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom color="primary" sx={{ mt: 4, pb: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
          Dispute Resolution
        </Typography>
        <Typography paragraph>
          If you have a concern about our privacy practices, please contact us first. If you are not satisfied with our response, you may have the right to file a complaint with the relevant data protection authority in your jurisdiction.
        </Typography>

        <Paper elevation={0} sx={{ 
          p: 3, 
          mt: 4, 
          bgcolor: theme.palette.grey[50],
          border: `1px solid ${theme.palette.grey[200]}`,
          borderRadius: 2
        }}>
          <Typography variant="h4" component="h2" gutterBottom color="primary">
            Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about this Privacy Policy or our data practices, please contact us at:
          </Typography>
          <Typography paragraph>
            <Box component="span" sx={{ fontWeight: 'bold' }}>Dealer Software Solutions, LLC</Box><br />
            445 S Figueroa St. Suite 2220<br />
            Los Angeles, CA 90071<br />
            Email: <Link href="mailto:info@dealeros.io">info@dealeros.io</Link><br />
            Phone: (888) 243-7544
          </Typography>
        </Paper>
        
        <Box sx={{ 
          textAlign: 'center', 
          mt: 4, 
          pt: 2, 
          borderTop: `1px solid ${theme.palette.divider}`, 
          color: theme.palette.text.secondary,
          fontSize: '0.875rem'
        }}>
          <Typography variant="body2">
            &copy; 2025 Dealer Software Solutions, LLC. All rights reserved.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;